import React from 'react';
import PropTypes from 'prop-types';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { transformContentForFaq } from 'site-modules/shared/components/faq/utils';
import { WRAPPER_TYPES } from 'site-modules/shared/components/faq/constants';
import { Faq } from 'site-modules/shared/components/faq/faq';
import { ArrowLink } from 'site-modules/shared/components/arrow-link/arrow-link';
import { HowItWorks } from 'client/site-modules/shared/components/appraisal/how-it-works/how-it-works';

const PART_SEPARATOR = '</p>';
const PARAGRAPHS_BEFORE_COLLAPSE_NUM = 2;

const getArticleParts = stringContent => stringContent.split(PART_SEPARATOR).map(part => `${part}${PART_SEPARATOR}`);

export function AppraisalInfoSection({ howItWorksContent, articlesContent, faqContent, pathname }) {
  const articles = articlesContent.children().map(article => {
    const { id, content } = article;
    const articleParts = getArticleParts(content);
    return { id, articleParts };
  });

  return (
    <div className="appraisal-info-section">
      <div className="appraisal-page-block bg-white px-1_5 py-2 mb-3">
        <HowItWorks content={howItWorksContent.child('how-it-works-content')} />
        <div className="my-1_5 pos-r">
          <ArrowLink to="/sell-car/" data-tracking-id="click_sell_my_car" rel="nofollow" className="mb-1">
            Sell Your Car - Get An Instant Cash Offer
          </ArrowLink>
        </div>
        <hr className="mt-1_5 mb-2" />
        {articles.map(({ id, articleParts }, ind) => (
          <div key={id}>
            <section className="content-section">
              <ContentFragment>{articleParts.slice(0, PARAGRAPHS_BEFORE_COLLAPSE_NUM).join('')}</ContentFragment>
              <ContentCollapse
                textClosed="Read more"
                textOpen="Read less"
                justify="justify-content-start"
                btnContainerClasses="m-0 p-0"
                btnClasses="p-0 size-16 text-primary-darker more-info-btn"
                btnTextClasses="font-weight-normal"
                showBtnBelow
                isEclipseFade={false}
                isOpenOnLoad={false}
              >
                <ContentFragment>{articleParts.slice(PARAGRAPHS_BEFORE_COLLAPSE_NUM).join('')}</ContentFragment>
              </ContentCollapse>
            </section>
            {ind !== articles.length - 1 && <hr className="mt-1_5 mb-2" />}
          </div>
        ))}
      </div>
      <section className="appraisal-page-block">
        <Faq
          {...transformContentForFaq(faqContent)}
          wrapperType={WRAPPER_TYPES.accordion}
          pathname={pathname}
          generateLearnMoreLinks
          isOpenCollapseOnLoad={false}
          visibleEntriesNum={5}
        />
      </section>
    </div>
  );
}

AppraisalInfoSection.propTypes = {
  howItWorksContent: CmsEntities.Content,
  articlesContent: CmsEntities.Content,
  faqContent: CmsEntities.Content,
  pathname: PropTypes.string,
};

AppraisalInfoSection.defaultProps = {
  howItWorksContent: DEFAULT_CONTENT,
  articlesContent: DEFAULT_CONTENT,
  faqContent: DEFAULT_CONTENT,
  pathname: '',
};
