import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';
import { AppointmentDrawerMap } from 'client/tracking/maps/appraisal/common/appointment-drawer';

const map = {
  partner_schedule_dropoff: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.DEALER_SITE_CLICKOUT
  ),
  partner_send_to_phone: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.SUBACTION_TEXT_OFFER
  ),
  dealer_select: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.SUBACTION_DEALER_SELECT
  ),
};

export const PartnerOfferMap = {
  ...AppointmentDrawerMap,
  ...map,
};
