import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop } from 'lodash';
import { TAB_IDS } from 'client/site-modules/shared/constants/search-by-module';
import { SearchByModuleContent } from './search-by-module-content';

export function SearchByModule(props) {
  const { isMobile, className, contentClassName } = props;

  const wrapperClassName = className || classnames('mb-md-0 mt-lg-2', { 'my-1_5': !isMobile, 'my-1': isMobile });
  return (
    <div className={wrapperClassName}>
      <SearchByModuleContent
        {...props}
        className={classnames('p-1 px-lg-1_5 rounded', contentClassName, { 'pb-1_75': isMobile })}
        tabsContainerClassName="mt-0_25 mb-1_5"
      />
    </div>
  );
}

SearchByModule.propTypes = {
  trackingMap: PropTypes.shape({}).isRequired,
  activeTab: PropTypes.string,
  isMobile: PropTypes.bool,
  isUsed: PropTypes.bool,
  isCpo: PropTypes.bool,
  hasCorePageRedirect: PropTypes.bool,
  disableMakeToggle: PropTypes.bool,
  disableModelToggle: PropTypes.bool,
  location: PropTypes.shape({}).isRequired,
  isSmallContainer: PropTypes.bool,
  onUpdateParams: PropTypes.func,
  className: PropTypes.string,
  showRadioButtons: PropTypes.bool,
  contentClassName: PropTypes.string,
};

SearchByModule.defaultProps = {
  activeTab: TAB_IDS.MAKE_MODEL_TAB,
  isMobile: false,
  isUsed: false,
  isCpo: false,
  hasCorePageRedirect: false,
  disableMakeToggle: false,
  disableModelToggle: false,
  isSmallContainer: false,
  onUpdateParams: noop,
  className: null,
  showRadioButtons: false,
  contentClassName: '',
};
