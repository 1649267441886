import { TrackingEvent } from 'client/tracking/event';
import { TrackingConstant } from 'client/tracking/constant';

export const AppointmentDrawerMap = {
  instant_offer_dealer_site_clickout: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.DEALER_SITE_CLICKOUT
  ),
  submit_schedule_appointment: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.SUBMIT_APPOINTMENT
  ),
  schedule_appointment_drawer_close: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.ACTION_CLOSE
  ),
  schedule_appointment_secondary_store_continue: TrackingEvent.actionProgress(
    TrackingConstant.ACTION_RECEIVE_EPO,
    TrackingConstant.SUBACTION_EPO_GET_APPOINTMENT_SLOTS
  ),
  instant_offer_call_dealer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_DEALER_ENGAGEMENT,
    TrackingConstant.CALL_DEALER
  ),
  partner_offer_directions_to_dealer: TrackingEvent.actionCompleted(
    TrackingConstant.ACTION_WEBSITE_CLICKOUT,
    TrackingConstant.GET_DIRECTIONS_TO_DEALER
  ),
};
