export const SELL_CAR_PAGE_CREATIVE_ID = 'edm-entry-sell-car-page';

export const CONTENT_CREATIVE_IDS = {
  HOW_IT_WORKS: 'edm-entry-how-it-works',
  WHY_EDMUNDS: 'edm-entry-why-edmunds',
  RESOURCES_FOR_YOU: 'edm-entry-resources-for-you',
  RELATED_INFORMATION: 'edm-entry-related-information',
};

export const CTA_TEXTS = {
  SELL_MY_CAR_NOW: 'Sell my car now',
  READ_MORE: 'Read more',
  READ_LESS: 'Read less',
  START_SELLING_YOUR_CAR: 'Start selling your car',
};
