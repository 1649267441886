import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from 'reactstrap/lib/Container';
import { SuggestedVehiclePricingCarouselProgressive } from './suggested-vehicle-pricing-carousel-progressive';

export function SuggestedVehiclePricingCarouselContainer({ className, showSeparator, separatorClassName, children }) {
  return (
    <Container className={className}>
      {children ? (
        <Fragment>
          <div className="d-lg-flex">
            <div style={{ flex: '1 1 auto' }}>
              <SuggestedVehiclePricingCarouselProgressive />
            </div>
            {children}
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <SuggestedVehiclePricingCarouselProgressive />
        </Fragment>
      )}
      {showSeparator && <hr className={separatorClassName} />}
    </Container>
  );
}

SuggestedVehiclePricingCarouselContainer.propTypes = {
  className: PropTypes.string,
  showSeparator: PropTypes.bool,
  separatorClassName: PropTypes.string,
  children: PropTypes.node,
};

SuggestedVehiclePricingCarouselContainer.defaultProps = {
  className: null,
  showSeparator: false,
  separatorClassName: null,
  children: null,
};
